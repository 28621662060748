import React, { Component } from 'react';
import s from './App.module.css';
import VisuallyHidden from '../components/VisuallyHidden';

import logo from '../assets/logo.svg';
import facebook from '../assets/facebook.svg';
import instagram from '../assets/instagram.svg';
import youtube from '../assets/youtube.svg';

class App extends Component {
  render() {
    return (
      <div className={s.root}>
        <header className={s.header}>
          <img src={logo} className={s.logo} alt="logo" />
          <VisuallyHidden>
            <h1>El güero del vocho</h1>
          </VisuallyHidden>
        </header>
        <footer className={s.footer}>
          <h2>Seguir "El güero del vocho" en</h2>
          <ul className={s.list}>
            <li>
              <a href="https://www.facebook.com/elguerodelvocho/">
                <img src={facebook} width={50} alt="" />
                <VisuallyHidden>Facebook</VisuallyHidden>
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/elguerodelvocho/">
                <img src={instagram} width={50} alt="" />
                <VisuallyHidden>Instagram</VisuallyHidden>
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/channel/UCIolsBiHqz05iII4IVA8ufQ">
                <img src={youtube} width={50} alt="" />
                <VisuallyHidden>YouTube</VisuallyHidden>
              </a>
            </li>
          </ul>
        </footer>
      </div>
    );
  }
}

export default App;
